import { render, staticRenderFns } from "./Team.vue?vue&type=template&id=798d98dd"
import script from "./Team.vue?vue&type=script&lang=js"
export * from "./Team.vue?vue&type=script&lang=js"
import style0 from "./Team.vue?vue&type=style&index=0&id=798d98dd&prod&lang=sass"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports