import Vue from "vue";
import App from "./App.vue";
import _ from "lodash";
import "./filters.js";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "@babel/polyfill";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import { VueReCaptcha } from "vue-recaptcha-v3";

Vue.prototype.$google_client_id =
  "310541575618-qbpojsnlnofp3dhkmhsomq5rbrn0av7r.apps.googleusercontent.com";

//Vue.prototype.$BASE_URL = "https://beta.api.hans-zimmer.com";
Vue.prototype.$BASE_URL = "https://api.hans-zimmer.com";

Vue.use(VueReCaptcha, { siteKey: "6LdVHpsqAAAAAOlz9dKqDwATNyEQudQNrQRIkGu6" });

Vue.config.productionTip = false;

Object.defineProperty(Vue.prototype, "_", { value: _ });

var vm = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");

function onSignIn(data) {
  console.log("USER DATA" + data);
}

Object.defineProperty(Vue.prototype, "onSignIn", { value: onSignIn });

/* Way to transform index.html?path=... to /... syntax if needed, but already handled in router (see router/index.js) */
/*
const urlParams = new URLSearchParams(window.location.search);
const pathParam = urlParams.get("path");
if (window.location.pathname === "/index.html") {
  let goTo = "";
  const idParam = urlParams.get("id");
  if ((pathParam === undefined) || (pathParam === null) || (pathParam === "")) {
    goTo = "news";
  } else if (pathParam === "news") {
    goTo = "news";
    if ((idParam !== undefined) && (idParam !== null) && (idParam.match("^[0-9]+$"))) {
      goTo += "/" + idParam;
    }
  } else if (pathParam === "forum") {
    goTo = "forum";
    if ((idParam !== undefined) && (idParam !== null) && (idParam.match("^[0-9]+$"))) {
      goTo += "/" + idParam;
    }
  } else if (pathParam === "team") {
    goTo = "team";
  } else if (pathParam === "discography") {
    goTo = "discography";
    if ((idParam !== undefined) && (idParam !== null) && (idParam.match("^[0-9]+$"))) {
      goTo += "/" + idParam;
      const prjIdParam = urlParams.get("project");
      if ((prjIdParam !== undefined) && (prjIdParam !== null) && (prjIdParam.match("^[0-9]+$"))) {
        goTo += "/project/" + prjIdParam
      }
    }
  }
  if (goTo !== "") {
    router.push("/" + goTo);
  }
}
*/

