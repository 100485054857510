import Vue from "vue";
import VueRouter from "vue-router";
import TheNews from "../views/News.vue";
import TheForum from "../views/Forum.vue";
import TheDiscography from "../views/Discography.vue";
import TheTeam from "../views/Team.vue";

function isSiteURL(path) {
  return ((path == "https://hans-zimmer.com")
       || (path == "http://hans-zimmer.com")
       || (path == "https://www.hans-zimmer.com")
       || (path == "http://www.hans-zimmer.com")
       || (path == "https://zimmer-website.s3.eu-west-3.amazonaws.com")
       || (path == "http://zimmer-website.s3.eu-west-3.amazonaws.com"));
}

/* Get the path with using index.html?path=... syntax */
function getDisplayedPath(path) {
  let newPath = "";
  let parsed = "";
  let prefix = "";
  let localPath = path;
  if (path.startsWith("http")) {
    if (URL.canParse(path)) {
      const pathURL = new URL(path);
      /*console.log("getDisplayedPath: " + pathURL.origin);*/
      if (isSiteURL(pathURL.origin)) {
        localPath = pathURL.pathname;
        prefix = pathURL.origin;
      }
    }
  }
  if (localPath.startsWith("/discography")) {
    newPath = "discography";
    parsed = localPath.substring("/discography".length);
    if (parsed.search("^/[0-9]+$") === 0) {
      newPath += "&id=" + parsed.substring(1);
    } else {
      const subPart = parsed.match("^/([0-9]+)/project/([0-9]+)$");
      if (subPart !== null) {
        newPath += "&id=" + subPart[1] + "&project=" + subPart[2];
      }
    }
  } else if (localPath.startsWith("/news")) {
    newPath = "news";
    parsed = localPath.substring("/news".length);
    if (parsed.search("^/[0-9]+$") === 0) {
      newPath += "&id=" + parsed.substring(1);
    }
  } else if (localPath.startsWith("/forum")) {
    newPath = "forum";
    parsed = localPath.substring("/forum".length);
    if (parsed.search("^/[0-9]+$") === 0) {
      newPath += "&id=" + parsed.substring(1);
    }
  } else if (localPath === "/team") {
    newPath = "team";
  }
  if (newPath !== "") {
    path = prefix + "/index.html?path=" + newPath;
  }
  return path;
}

/* Get the path with using /... syntax */
function getInternalPath(to) {
  let result = null;
  if (typeof to === "string") {
    let urlPathName = "";
    if (URL.canParse(to)) {
      const toURL = new URL(to);
      /*console.log("getInternalPath: " + toURL.origin);*/
      if ((isSiteURL(toURL.origin)) && (toURL.pathname === "/index.html")) {
        urlPathName = toURL.pathname + toURL.search;
      }
    } else if ((to.startsWith("/index.html?")) || (to === "/index.html")) {
      urlPathName = to;
    }
    if (urlPathName === "/index.html") {
      result = "/news";
    } else if (urlPathName !== "") {
      let goTo = "";
      const urlParams = new URLSearchParams(to.substring("/index.html".length));
      const pathParam = urlParams.get("path");
      if ((pathParam !== undefined) && (pathParam !== null) && (pathParam !== "")) {
        const idParam = urlParams.get("id");
        if (pathParam === "news") {
          result = "/news";
          if ((idParam !== undefined) && (idParam !== null) && (idParam.match("^[0-9]+$"))) {
            result += "/" + idParam;
          }
        } else if (pathParam === "forum") {
          result = "/forum";
          if ((idParam !== undefined) && (idParam !== null) && (idParam.match("^[0-9]+$"))) {
            result += "/" + idParam;
          }
        } else if (pathParam === "team") {
          result = "/team";
        } else if (pathParam === "discography") {
          result = "/discography";
          if ((idParam !== undefined) && (idParam !== null) && (idParam.match("^[0-9]+$"))) {
            const prjIdParam = urlParams.get("project");
            result += "/" + idParam;
            if ((prjIdParam !== undefined) && (prjIdParam !== null) && (prjIdParam.match("^[0-9]+$"))) {
              result += "/project/" + prjIdParam
            }
          }
        }
      }
    }
  }
  if (result === null) {
    result = to;
  }
  return result;
}

Vue.use(VueRouter);

const routes = [
  { path: "/", redirect: "/news" },
  {
    path: "/news",
    name: "News",
    component: TheNews
  },
  {
    path: "/news/:news_id",
    name: "News",
    component: TheNews
  },
  {
    path: "/forum",
    name: "Forum",
    component: TheForum
  },
  {
    path: "/forum/:topic_id",
    name: "Forum",
    component: TheForum
  },
  {
    path: "/team",
    name: "Team",
    component: TheTeam
  },
  {
    path: "/discography",
    name: "Discography",
    component: TheDiscography
  },
  {
    path: "/discography/:composer_id",
    name: "Discography",
    component: TheDiscography
  },
  {
    path: "/discography/:composer_id/project/:project_id",
    name: "Discography",
    component: TheDiscography
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

if (router.mode === "history") {
  /* Override methods to be able to navigate with using index.html?path=... externally and /... internally
   * to workaround S3 incapability to perform redirection for unknown objects */
  const originalEnsureURL = Object.getPrototypeOf(router.history).ensureURL;
  Object.getPrototypeOf(router.history).ensureURL = function ensureURL(push) {
    const savedCurrent = this.current;
    let localCurrent = Object.assign({}, this.current);
    localCurrent.fullPath = getDisplayedPath(this.current.fullPath);
    this.current = localCurrent;
    originalEnsureURL.call(this, push);
    this.current = savedCurrent;
  };
  const originalTransitionTo = Object.getPrototypeOf(router.history).transitionTo;
  Object.getPrototypeOf(router.history).transitionTo = function transitionTo(location, onComplete, onAbort) {
    const modOnComplete = function(myRoute) {
      let localRoute = Object.assign({}, myRoute);
      localRoute.fullPath = getDisplayedPath(myRoute.fullPath);
      onComplete && onComplete(localRoute);
    }
    let localLocation = getInternalPath(location);
    originalTransitionTo.call(this, localLocation, modOnComplete, onAbort);
  };
  /*const originalResolve = Object.getPrototypeOf(router).resolve;
  Object.getPrototypeOf(router).resolve = function resolve(to, current, append) {
    const { location, route, href, normalizedTo, resolved } = originalResolve.call(this, to, current, append);
    const newHref = getDisplayedPath(href);
    return { location, route, newHref, normalizedTo, resolved };
  };*/
} else {
  /* Fallback method in case history cannot be used */
  router.afterEach((to, from) => {
    let newPath = "";
    let parsed = "";
    if (to.path.startsWith("/discography")) {
      newPath = "discography";
      parsed = to.path.substring("/discography".length);
      if (parsed.search("^/[0-9]+$") === 0) {
        newPath += "&id=" + parsed.substring(1);
      }
      else {
        const subPart = parsed.match("^/([0-9]+)/project/([0-9]+)$");
        if (subPart !== null) {
          newPath += "&id=" + subPart[1] + "&project=" + subPart[2];
        }
      }
    } else if (to.path.startsWith("/news")) {
      newPath = "news";
      parsed = to.path.substring("/news".length);
      if (parsed.search("^/[0-9]+$") === 0) {
        newPath += "&id=" + parsed.substring(1);
      }
    } else if (to.path.startsWith("/forum")) {
      newPath = "forum";
      parsed = to.path.substring("/forum".length);
      if (parsed.search("^/[0-9]+$") === 0) {
        newPath += "&id=" + parsed.substring(1);
      }
    } else if (to.path === "/team") {
      newPath = "team";
    }
    if (newPath !== "") {
      const stateCopy = Object.assign({}, window.history.state)
      window.history.replaceState(stateCopy, "", "/index.html?path=" + newPath);
    }
  });

  router.beforeResolve((to, from, next) => {
    let goTo = "";
    if ((to.fullPath.startsWith("/index.html?")) && (to.query.path !== undefined) && (to.query.path !== null) && (to.query.path !== "")) {
      const idParam = to.query.id;
      if (to.query.path === "news") {
        goTo = "news";
        if ((idParam !== undefined) && (idParam !== null) && (idParam.match("^[0-9]+$"))) {
          goTo += "/" + idParam;
        }
      } else if (to.query.path === "forum") {
        goTo = "forum";
        if ((idParam !== undefined) && (idParam !== null) && (idParam.match("^[0-9]+$"))) {
          goTo += "/" + idParam;
        }
      } else if (to.query.path === "team") {
        goTo = "team";
      } else if (to.query.path === "discography") {
        goTo = "discography";
        if ((idParam !== undefined) && (idParam !== null) && (idParam.match("^[0-9]+$"))) {
          goTo += "/" + idParam;
          const prjIdParam = to.query.project;
          if ((prjIdParam !== undefined) && (prjIdParam !== null) && (prjIdParam.match("^[0-9]+$"))) {
            goTo += "/project/" + prjIdParam
          }
        }
      }
    } else if (to.fullPath === "/index.html") {
      goTo = "news";
    }
    if (goTo !== "") {
      next("/" + goTo);
    } else {
      next();
    }
  });
}

export default router;
