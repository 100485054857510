<template>
  <v-container fluid style="width: 100%">
    <v-row>
      <v-col v-if="creatingTopic" style="max-width: 700px" class="mx-auto">
        <v-btn class="mb-5" @click="toggleCreatingTopic">Close</v-btn>
        <ItemCommentPost type="topic" @commentposted="createTopic" />
      </v-col>
      <v-col v-else class="text-center">
        <v-btn @click="toggleCreatingTopic"
          >Create NEW Topic
          <v-icon class="ml-3">mdi-access-point</v-icon></v-btn
        >
      </v-col>
    </v-row>
    <v-row class="text-center">
      <v-col
        v-if="loading"
        cols="12"
        md="6"
        offset-md="3"
        class="mt-3 centerPannel"
      >
        <Loader />
      </v-col>
      <v-col
        v-else
        cols="12"
        md="6"
        offset-md="3"
        class="centerPannel"
        :class="{
          'mt-n5': $vuetify.breakpoint.xs,
          'mt-3': $vuetify.breakpoint.mdAndUp
        }"
      >
        <div v-if="$vuetify.breakpoint.xs" class="title text-left ml-5 mb-n3">
          Latest Topic
        </div>
        <ListTopics v-if="!isSpecificTopic" />
        <div v-else>
          <v-row>
            <v-btn @click="returnToTopicList" class="mb-10 secondary"
              >← Return to Topic List</v-btn
            >
          </v-row>
          <v-row>
            <ItemTopic v-if="specifictopic" :topic="specifictopic" />
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SendRequestMixin from "../mixins/SendRequestMixin";

export default {
  name: "TheForum",
  mixins: [SendRequestMixin],
  data() {
    return {
      fetchingData: -1,
      loading: true,
      specifictopic: null,
      creatingTopic: false
    };
  },
  components: {
    ListTopics: () => import("../components/ListTopics"),
    ItemTopic: () => import("../components/ItemTopic"),
    Loader: () => import("../components/loaders/Loader"),
    ItemCommentPost: () => import("../components/ItemCommentPost")
  },
  computed: {
    isSpecificTopic() {
      if ((this.$route.params.topic_id != undefined) && (this.$route.params.topic_id.match("^[0-9]+$"))) return true;
      return false;
    },
    listTopics() {
      return this.$store.getters["topics"];
    }
  },
  watch: {
    "$route.params.topic_id": function() {
      this.loadData();
    }
  },
  methods: {
    toggleCreatingTopic() {
      this.creatingTopic = !this.creatingTopic;
    },
    createTopic(topic) {
      var _vm = this;
      this.loading = true;

      topic["description"] = topic["content"];
      this.$store
        .dispatch("postData", {
          data: topic,
          store_as: "topic",
          endpoint: "/post/topic"
        })
        .then(data => {
          _vm.loading = false;
          console.log("SHOULD ADD");
          console.log(data);
          var topics = _vm._.cloneDeep(_vm.listTopics);
          topics.unshift(data);

          _vm.creatingTopic = false;

          this.$store.commit("mutate", {
            property: "topics",
            with: topics
          });
        });
    },
    returnToTopicList() {
      this.$router.push("/forum");
      this.loadData();
    },
    loadData() {
      var _vm = this;

      if (this.isSpecificTopic) {
        this.sendRequest({
          endpoint:
            "/get/topic/" +
            this.$route.params.topic_id +
            "?relations=author,comments",
          store_as: "specifictopic"
        });
      } else {
        if (
          this.$store.getters["topics"].length == 0 &&
          this.fetchingData == -1
        ) {
          this.fetchingData = 0;
          this.$store
            .dispatch("getData", {
              endpoint:
                '/get/topic?limit=11&order_by=id&relations=author,comments&filter_by={"display_in_Topic_list":true}',
              store_as: "topics"
            })
            .then(() => {
              _vm.loading = false;
            });
        }
      }

      if (this.$store.getters["topics"].length != 0) _vm.loading = false;
    }
  },
  created() {
    this.loadData();
  }
};
</script>
<style lang="sass">
@media screen and (min-width: 1264px)
// .container
//     max-width: 855px !important

.leftPannel, .rightPannel
  position: fixed

.rightPannel
  right: 0px

.centerPannel
  margin: auto
</style>
