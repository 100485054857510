<template>
  <v-container fluid class="containerDisco">
    <v-row v-if="isProjectPage" justify="center">
      <ItemProject :project-id="projectId" />
    </v-row>
    <v-row class="text-center" v-show="!isProjectPage">
      <TheYearPicker
        style="position: fixed"
        v-if="$vuetify.breakpoint.mdAndUp"
      />
      <v-col cols="12" :class="{ 'ml-6': $vuetify.breakpoint.mdAndUp }">
        <ListProjects />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "TheDiscography",
  data() {
    return {
      fetchingData: -1
    };
  },
  components: {
    TheYearPicker: () => import("../components/TheYearPicker.vue"),
    ItemProject: () => import("../components/ItemProject.vue"),
    ListProjects: () => import("../components/ListProjects.vue")
  },
  computed: {
    isProjectPage() {
      return (this.projectId != undefined) && (this.projectId.match("^[0-9]+$"));
    },
    projectId() {
      return this.$route.params.project_id;
    }
  },
  updated() {
    if (this.$store.getters["artists"].length == 0 && this.fetchingData == -1) {
      this.fetchingData = 0;
      this.$store.dispatch("getData", {
        endpoint: "/get/artist?relations=job",
        store_as: "artists"
      });
    }
  },
  created() {
    if (this.$store.getters["artists"].length == 0 && this.fetchingData == -1) {
      this.fetchingData = 0;
      this.$store.dispatch("getData", {
        endpoint: "/get/artist?relations=job",
        store_as: "artists"
      });
    }
  }
};
</script>
<style lang="sass">
@media screen and (min-width: 1264px)
.container.containerDisco
    max-width: 100% !important
</style>
