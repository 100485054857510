<template>
  <v-container fluid style="width: 100%">
    <v-row class="text-center">
      <v-col
        cols="12"
        md="3"
        :class="{ 'leftPannel ml-12': $vuetify.breakpoint.mdAndUp }"
      >
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="12"
            :class="{ 'mt-n5': $vuetify.breakpoint.xs }"
          >
            <WidgetUsers
              :style="{
                width: '100%',
                maxWidth: $vuetify.breakpoint.xs ? '' : '300px'
              }"
              class="mt-3 mx-auto"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="12"
            :class="{ 'mt-n5': $vuetify.breakpoint.xs }"
          >
            <WidgetProjects
              :style="{
                width: '100%',
                maxWidth: $vuetify.breakpoint.xs ? '' : '300px'
              }"
              class="mt-3 mx-auto"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col
        v-if="loading"
        cols="12"
        md="3"
        offset-md="5"
        class="mt-3 centerPannel"
      >
        <TheLoader />
      </v-col>
      <v-col
        v-else
        cols="12"
        md="4"
        offset-md="4"
        class="centerPannel"
        :class="{
          'mt-n5': $vuetify.breakpoint.xs,
          'mt-3': $vuetify.breakpoint.mdAndUp
        }"
      >
        <div><AdminPublish /></div>
        <div><ItemQuestion /></div>
        <div v-if="$vuetify.breakpoint.xs" class="title text-left ml-5 mb-n3">
          Latest News
        </div>
        <ListNews v-if="!isSpecificNews" />
        <div v-else>
          <v-row>
            <v-btn @click="returnToNewsList" class="mb-10 secondary"
              >← Return to News List</v-btn
            >
          </v-row>
          <v-row>
            <ItemNews v-if="specificNews" :news="specificNews" />
          </v-row>
        </div>
      </v-col>
      <v-col
        cols="12"
        md="3"
        v-if="$vuetify.breakpoint.mdAndUp"
        class="mt-3 centerPannel"
        :class="{ 'rightPannel mr-12': $vuetify.breakpoint.mdAndUp }"
      >
        <v-row>
          <v-col cols="12">
            <TheCollectible />
          </v-col>
          <v-col cols="12">
            <NextEvents />
          </v-col>
          <v-col cols="12">
            <v-hover v-slot:default="{ hover }">
              <a
                href="https://www.facebook.com/HansZimmerCom"
                target="_blank"
                class="d-inline-block"
              >
                <v-img
                  height="20"
                  width="20"
                  src="@/assets/fb_grey.png"
                  :style="{ opacity: hover ? 0.7 : 1 }"
                />
              </a>
            </v-hover>
            <v-hover v-slot:default="{ hover }">
              <a
                href="https://www.youtube.com/user/hanszimmercom"
                target="_blank"
                class="d-inline-block ml-3"
              >
                <v-img
                  height="20"
                  width="90"
                  src="@/assets/youtube_grey.png"
                  :style="{ opacity: hover ? 0.7 : 1 }"
                />
              </a>
            </v-hover>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SendRequestMixin from "../mixins/SendRequestMixin";

export default {
  name: "TheNews",
  mixins: [SendRequestMixin],
  data() {
    return {
      fetchingData: -1,
      loading: false,
      specificNews: null
    };
  },
  components: {
    WidgetUsers: () => import("../components/cards/WidgetUsers.vue"),
    WidgetProjects: () => import("../components/cards/WidgetProjects.vue"),
    ListNews: () => import("../components/ListNews"),
    ItemQuestion: () => import("../components/ItemQuestion"),
    ItemNews: () => import("../components/ItemNews"),
    TheLoader: () => import("../components/loaders/Loader"),
    NextEvents: () => import("../components/cards/NextEvents"),
    TheCollectible: () => import("../components/cards/TheCollectible"),
    AdminPublish: () => import("../components/cards/AdminPublish")
  },
  computed: {
    isSpecificNews() {
      if ((this.$route.params.news_id != undefined) && (this.$route.params.news_id.match("^[0-9]+$"))) return true;
      return false;
    }
  },
  watch: {
    "$route.params.news_id": function() {
      this.loadData();
    }
  },
  methods: {
    returnToNewsList() {
      this.$router.push("/news");
      this.loadData();
    },
    loadData() {
      var _vm = this;

      if (this.isSpecificNews) {
        this.sendRequest({
          endpoint:
            "/get/news/" +
            this.$route.params.news_id +
            "?relations=author,comments",
          store_as: "specificNews"
        });
      } else {
        if (
          this.$store.getters["news"].length == 0 &&
          this.fetchingData == -1
        ) {
          this.fetchingData = 0;
          this.$store
            .dispatch("getData", {
              endpoint:
                '/get/news?limit=15&order_by=id&relations=author,comments&filter_by={"display_in_news_list":true}',
              store_as: "news"
            })
            .then(() => {
              _vm.loading = false;
            });
        }
      }

      if (this.$store.getters["news"].length != 0) _vm.loading = false;
    }
  },
  created() {
    this.loadData();
  }
};
</script>
<style lang="sass">
@media screen and (min-width: 1264px)
// .container
//     max-width: 855px !important

.leftPannel, .rightPannel
	position: fixed

.rightPannel
	right: 0px

.centerPannel
	margin: auto
</style>
